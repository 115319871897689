/* eslint-disable no-loss-of-precision */
import { Ii } from './ecg_waves';
import { respiratoryWave } from './respiratoryWave';
import { getECG } from './dataGenerator';
import { plethysmographyWave } from './plethysmographyWave';
import { co2Wave } from './co2Wave';

const VITAL_SIGN_INTERVAL = 12000;

function subscriptionFactory(id, callback) {
  let observationFunction = () => { /* */ };

  const interval = setInterval(() => {
    callback(id, observationFunction);
  }, VITAL_SIGN_INTERVAL);

  const subscription = {
    onData: (dataCallback) => {
      observationFunction = dataCallback;
    },
    unsubscribe: () => {
      clearInterval(interval);
    },
  };

  return subscription;
}

export const realTimeWaves = {
  RealTimeWaves: ({ ids }) => subscriptionFactory(ids, (id, onData) => {
    onData({
      realTimeWaves: {
        waveIi: getECG(Ii),
        respiratoryWave: getECG(respiratoryWave),
        plethysmographyWave: getECG(plethysmographyWave),
        carbonDioxideWave: getECG(co2Wave),
      },
    });
  }),
};
