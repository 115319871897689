export const plethysmographyWave = [
  {
    x: 0,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 7.8125,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 15.625,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 23.4375,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 31.25,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 39.0625,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 46.875,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 54.6875,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 62.5,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 70.3125,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 78.125,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 85.9375,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 93.75,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 101.5625,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 109.375,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 117.1875,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 125,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 132.8125,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 140.625,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 148.4375,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 156.25,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 164.0625,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 171.875,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 179.6875,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 187.5,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 195.3125,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 203.125,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 210.9375,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 218.75,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 226.5625,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 234.375,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 242.1875,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 250,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 257.8125,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 265.625,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 273.4375,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 281.25,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 289.0625,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 296.875,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 304.6875,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 312.5,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 320.3125,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 328.125,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 335.9375,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 343.75,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 351.5625,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 359.375,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 367.1875,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 375,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 382.8125,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 390.625,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 398.4375,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 406.25,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 414.0625,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 421.875,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 429.6875,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 437.5,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 445.3125,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 453.125,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 460.9375,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 468.75,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 476.5625,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 484.375,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 492.1875,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 500,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 507.8125,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 515.625,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 523.4375,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 531.25,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 539.0625,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 546.875,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 554.6875,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 562.5,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 570.3125,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 578.125,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 585.9375,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 593.75,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 601.5625,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 609.375,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 617.1875,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 625,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 632.8125,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 640.625,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 648.4375,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 656.25,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 664.0625,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 671.875,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 679.6875,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 687.5,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 695.3125,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 703.125,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 710.9375,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 718.75,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 726.5625,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 734.375,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 742.1875,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 750,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 757.8125,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 765.625,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 773.4375,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 781.25,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 789.0625,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 796.875,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 804.6875,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 812.5,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 820.3125,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 828.125,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 835.9375,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 843.75,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 851.5625,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 859.375,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 867.1875,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 875,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 882.8125,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 890.625,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 898.4375,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 906.25,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 914.0625,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 921.875,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 929.6875,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 937.5,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 945.3125,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 953.125,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 960.9375,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 968.75,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 976.5625,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 984.375,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 992.1875,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 1000,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 1007.8125,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 1015.625,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 1023.4375,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 1031.25,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 1039.0625,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 1046.875,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 1054.6875,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 1062.5,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 1070.3125,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 1078.125,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 1085.9375,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 1093.75,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 1101.5625,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 1109.375,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 1117.1875,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 1125,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 1132.8125,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 1140.625,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 1148.4375,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 1156.25,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 1164.0625,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 1171.875,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 1179.6875,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 1187.5,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 1195.3125,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 1203.125,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 1210.9375,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 1218.75,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 1226.5625,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 1234.375,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 1242.1875,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 1250,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 1257.8125,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 1265.625,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 1273.4375,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 1281.25,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 1289.0625,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 1296.875,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 1304.6875,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 1312.5,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 1320.3125,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 1328.125,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 1335.9375,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 1343.75,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 1351.5625,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 1359.375,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 1367.1875,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 1375,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 1382.8125,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 1390.625,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 1398.4375,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 1406.25,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 1414.0625,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 1421.875,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 1429.6875,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 1437.5,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 1445.3125,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 1453.125,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 1460.9375,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 1468.75,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 1476.5625,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 1484.375,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 1492.1875,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 1500,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 1507.8125,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 1515.625,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 1523.4375,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 1531.25,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 1539.0625,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 1546.875,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 1554.6875,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 1562.5,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 1570.3125,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 1578.125,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 1585.9375,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 1593.75,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 1601.5625,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 1609.375,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 1617.1875,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 1625,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 1632.8125,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 1640.625,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 1648.4375,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 1656.25,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 1664.0625,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 1671.875,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 1679.6875,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 1687.5,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 1695.3125,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 1703.125,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 1710.9375,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 1718.75,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 1726.5625,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 1734.375,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 1742.1875,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 1750,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 1757.8125,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 1765.625,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 1773.4375,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 1781.25,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 1789.0625,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 1796.875,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 1804.6875,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 1812.5,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 1820.3125,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 1828.125,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 1835.9375,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 1843.75,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 1851.5625,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 1859.375,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 1867.1875,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 1875,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 1882.8125,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 1890.625,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 1898.4375,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 1906.25,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 1914.0625,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 1921.875,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 1929.6875,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 1937.5,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 1945.3125,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 1953.125,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 1960.9375,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 1968.75,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 1976.5625,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 1984.375,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 1992.1875,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 2000,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 2007.8125,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 2015.625,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 2023.4375,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 2031.25,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 2039.0625,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 2046.875,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 2054.6875,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 2062.5,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 2070.3125,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 2078.125,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 2085.9375,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 2093.75,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 2101.5625,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 2109.375,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 2117.1875,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 2125,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 2132.8125,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 2140.625,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 2148.4375,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 2156.25,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 2164.0625,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 2171.875,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 2179.6875,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 2187.5,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 2195.3125,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 2203.125,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 2210.9375,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 2218.75,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 2226.5625,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 2234.375,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 2242.1875,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 2250,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 2257.8125,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 2265.625,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 2273.4375,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 2281.25,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 2289.0625,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 2296.875,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 2304.6875,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 2312.5,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 2320.3125,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 2328.125,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 2335.9375,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 2343.75,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 2351.5625,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 2359.375,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 2367.1875,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 2375,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 2382.8125,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 2390.625,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 2398.4375,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 2406.25,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 2414.0625,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 2421.875,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 2429.6875,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 2437.5,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 2445.3125,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 2453.125,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 2460.9375,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 2468.75,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 2476.5625,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 2484.375,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 2492.1875,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 2500,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 2507.8125,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 2515.625,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 2523.4375,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 2531.25,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 2539.0625,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 2546.875,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 2554.6875,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 2562.5,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 2570.3125,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 2578.125,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 2585.9375,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 2593.75,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 2601.5625,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 2609.375,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 2617.1875,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 2625,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 2632.8125,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 2640.625,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 2648.4375,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 2656.25,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 2664.0625,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 2671.875,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 2679.6875,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 2687.5,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 2695.3125,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 2703.125,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 2710.9375,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 2718.75,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 2726.5625,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 2734.375,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 2742.1875,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 2750,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 2757.8125,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 2765.625,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 2773.4375,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 2781.25,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 2789.0625,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 2796.875,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 2804.6875,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 2812.5,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 2820.3125,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 2828.125,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 2835.9375,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 2843.75,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 2851.5625,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 2859.375,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 2867.1875,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 2875,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 2882.8125,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 2890.625,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 2898.4375,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 2906.25,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 2914.0625,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 2921.875,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 2929.6875,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 2937.5,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 2945.3125,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 2953.125,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 2960.9375,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 2968.75,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 2976.5625,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 2984.375,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 2992.1875,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3000,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3007.8125,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3015.625,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 3023.4375,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 3031.25,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 3039.0625,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 3046.875,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 3054.6875,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 3062.5,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 3070.3125,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 3078.125,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 3085.9375,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 3093.75,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 3101.5625,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 3109.375,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 3117.1875,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 3125,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 3132.8125,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 3140.625,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 3148.4375,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 3156.25,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 3164.0625,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 3171.875,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 3179.6875,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 3187.5,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 3195.3125,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 3203.125,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 3210.9375,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 3218.75,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 3226.5625,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 3234.375,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 3242.1875,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 3250,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 3257.8125,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 3265.625,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 3273.4375,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 3281.25,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 3289.0625,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 3296.875,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 3304.6875,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 3312.5,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 3320.3125,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 3328.125,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 3335.9375,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 3343.75,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 3351.5625,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 3359.375,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 3367.1875,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 3375,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 3382.8125,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 3390.625,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 3398.4375,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 3406.25,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 3414.0625,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 3421.875,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 3429.6875,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 3437.5,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 3445.3125,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 3453.125,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 3460.9375,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 3468.75,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 3476.5625,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 3484.375,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 3492.1875,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 3500,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 3507.8125,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 3515.625,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 3523.4375,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 3531.25,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 3539.0625,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 3546.875,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 3554.6875,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 3562.5,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 3570.3125,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 3578.125,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 3585.9375,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 3593.75,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 3601.5625,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 3609.375,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 3617.1875,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 3625,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 3632.8125,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 3640.625,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 3648.4375,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 3656.25,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 3664.0625,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 3671.875,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 3679.6875,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 3687.5,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 3695.3125,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 3703.125,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 3710.9375,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 3718.75,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 3726.5625,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 3734.375,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 3742.1875,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 3750,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 3757.8125,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 3765.625,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 3773.4375,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 3781.25,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 3789.0625,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 3796.875,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 3804.6875,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 3812.5,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 3820.3125,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 3828.125,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 3835.9375,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 3843.75,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 3851.5625,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 3859.375,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 3867.1875,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 3875,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 3882.8125,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 3890.625,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 3898.4375,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 3906.25,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 3914.0625,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 3921.875,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 3929.6875,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 3937.5,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 3945.3125,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 3953.125,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 3960.9375,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 3968.75,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3976.5625,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3984.375,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 3992.1875,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 4000,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 4007.8125,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 4015.625,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 4023.4375,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 4031.25,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 4039.0625,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 4046.875,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 4054.6875,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 4062.5,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 4070.3125,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 4078.125,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 4085.9375,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 4093.75,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 4101.5625,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 4109.375,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 4117.1875,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 4125,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 4132.8125,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 4140.625,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 4148.4375,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 4156.25,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 4164.0625,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 4171.875,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 4179.6875,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 4187.5,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 4195.3125,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 4203.125,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 4210.9375,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 4218.75,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 4226.5625,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 4234.375,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 4242.1875,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 4250,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 4257.8125,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 4265.625,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 4273.4375,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 4281.25,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 4289.0625,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 4296.875,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 4304.6875,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 4312.5,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 4320.3125,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 4328.125,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 4335.9375,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 4343.75,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 4351.5625,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 4359.375,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 4367.1875,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 4375,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 4382.8125,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 4390.625,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 4398.4375,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 4406.25,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 4414.0625,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 4421.875,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 4429.6875,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 4437.5,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 4445.3125,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 4453.125,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 4460.9375,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 4468.75,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 4476.5625,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 4484.375,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 4492.1875,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 4500,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 4507.8125,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 4515.625,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 4523.4375,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 4531.25,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 4539.0625,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 4546.875,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 4554.6875,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 4562.5,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 4570.3125,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 4578.125,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 4585.9375,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 4593.75,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 4601.5625,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 4609.375,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 4617.1875,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 4625,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 4632.8125,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 4640.625,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 4648.4375,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 4656.25,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 4664.0625,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 4671.875,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 4679.6875,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 4687.5,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 4695.3125,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 4703.125,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 4710.9375,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 4718.75,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 4726.5625,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 4734.375,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 4742.1875,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 4750,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 4757.8125,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 4765.625,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 4773.4375,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 4781.25,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 4789.0625,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 4796.875,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 4804.6875,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 4812.5,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 4820.3125,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 4828.125,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 4835.9375,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 4843.75,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 4851.5625,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 4859.375,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 4867.1875,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 4875,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 4882.8125,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 4890.625,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 4898.4375,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 4906.25,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 4914.0625,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 4921.875,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 4929.6875,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 4937.5,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 4945.3125,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 4953.125,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 4960.9375,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 4968.75,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 4976.5625,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 4984.375,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 4992.1875,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 5000,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 5007.8125,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 5015.625,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 5023.4375,
    y: 0.4969230890274048,
    __typename: 'Points',
  },
  {
    x: 5031.25,
    y: 0.48846152424812317,
    __typename: 'Points',
  },
  {
    x: 5039.0625,
    y: 0.47871795296669006,
    __typename: 'Points',
  },
  {
    x: 5046.875,
    y: 0.4699999988079071,
    __typename: 'Points',
  },
  {
    x: 5054.6875,
    y: 0.4610256552696228,
    __typename: 'Points',
  },
  {
    x: 5062.5,
    y: 0.4505128264427185,
    __typename: 'Points',
  },
  {
    x: 5070.3125,
    y: 0.4384615421295166,
    __typename: 'Points',
  },
  {
    x: 5078.125,
    y: 0.42615383863449097,
    __typename: 'Points',
  },
  {
    x: 5085.9375,
    y: 0.4128205180168152,
    __typename: 'Points',
  },
  {
    x: 5093.75,
    y: 0.399487167596817,
    __typename: 'Points',
  },
  {
    x: 5101.5625,
    y: 0.38538461923599243,
    __typename: 'Points',
  },
  {
    x: 5109.375,
    y: 0.3717948794364929,
    __typename: 'Points',
  },
  {
    x: 5117.1875,
    y: 0.35769230127334595,
    __typename: 'Points',
  },
  {
    x: 5125,
    y: 0.34410256147384644,
    __typename: 'Points',
  },
  {
    x: 5132.8125,
    y: 0.3302564024925232,
    __typename: 'Points',
  },
  {
    x: 5140.625,
    y: 0.3197436034679413,
    __typename: 'Points',
  },
  {
    x: 5148.4375,
    y: 0.3102564215660095,
    __typename: 'Points',
  },
  {
    x: 5156.25,
    y: 0.30000001192092896,
    __typename: 'Points',
  },
  {
    x: 5164.0625,
    y: 0.2892307639122009,
    __typename: 'Points',
  },
  {
    x: 5171.875,
    y: 0.27897435426712036,
    __typename: 'Points',
  },
  {
    x: 5179.6875,
    y: 0.26923078298568726,
    __typename: 'Points',
  },
  {
    x: 5187.5,
    y: 0.26102563738822937,
    __typename: 'Points',
  },
  {
    x: 5195.3125,
    y: 0.25333333015441895,
    __typename: 'Points',
  },
  {
    x: 5203.125,
    y: 0.24743589758872986,
    __typename: 'Points',
  },
  {
    x: 5210.9375,
    y: 0.24307692050933838,
    __typename: 'Points',
  },
  {
    x: 5218.75,
    y: 0.23974359035491943,
    __typename: 'Points',
  },
  {
    x: 5226.5625,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 5234.375,
    y: 0.23846153914928436,
    __typename: 'Points',
  },
  {
    x: 5242.1875,
    y: 0.24051281809806824,
    __typename: 'Points',
  },
  {
    x: 5250,
    y: 0.24435897171497345,
    __typename: 'Points',
  },
  {
    x: 5257.8125,
    y: 0.24948717653751373,
    __typename: 'Points',
  },
  {
    x: 5265.625,
    y: 0.2558974325656891,
    __typename: 'Points',
  },
  {
    x: 5273.4375,
    y: 0.2635897397994995,
    __typename: 'Points',
  },
  {
    x: 5281.25,
    y: 0.2730769217014313,
    __typename: 'Points',
  },
  {
    x: 5289.0625,
    y: 0.28410255908966064,
    __typename: 'Points',
  },
  {
    x: 5296.875,
    y: 0.29692307114601135,
    __typename: 'Points',
  },
  {
    x: 5304.6875,
    y: 0.3110256493091583,
    __typename: 'Points',
  },
  {
    x: 5312.5,
    y: 0.3264102637767792,
    __typename: 'Points',
  },
  {
    x: 5320.3125,
    y: 0.3430769145488739,
    __typename: 'Points',
  },
  {
    x: 5328.125,
    y: 0.3610256314277649,
    __typename: 'Points',
  },
  {
    x: 5335.9375,
    y: 0.3782051205635071,
    __typename: 'Points',
  },
  {
    x: 5343.75,
    y: 0.3948718011379242,
    __typename: 'Points',
  },
  {
    x: 5351.5625,
    y: 0.413589745759964,
    __typename: 'Points',
  },
  {
    x: 5359.375,
    y: 0.4341025650501251,
    __typename: 'Points',
  },
  {
    x: 5367.1875,
    y: 0.45538461208343506,
    __typename: 'Points',
  },
  {
    x: 5375,
    y: 0.4769230782985687,
    __typename: 'Points',
  },
  {
    x: 5382.8125,
    y: 0.4992307722568512,
    __typename: 'Points',
  },
  {
    x: 5390.625,
    y: 0.5212820768356323,
    __typename: 'Points',
  },
  {
    x: 5398.4375,
    y: 0.5430769324302673,
    __typename: 'Points',
  },
  {
    x: 5406.25,
    y: 0.5651282072067261,
    __typename: 'Points',
  },
  {
    x: 5414.0625,
    y: 0.5861538648605347,
    __typename: 'Points',
  },
  {
    x: 5421.875,
    y: 0.6066666841506958,
    __typename: 'Points',
  },
  {
    x: 5429.6875,
    y: 0.6264102458953857,
    __typename: 'Points',
  },
  {
    x: 5437.5,
    y: 0.642307698726654,
    __typename: 'Points',
  },
  {
    x: 5445.3125,
    y: 0.6561538577079773,
    __typename: 'Points',
  },
  {
    x: 5453.125,
    y: 0.6712820529937744,
    __typename: 'Points',
  },
  {
    x: 5460.9375,
    y: 0.6861538290977478,
    __typename: 'Points',
  },
  {
    x: 5468.75,
    y: 0.7002564072608948,
    __typename: 'Points',
  },
  {
    x: 5476.5625,
    y: 0.7130769491195679,
    __typename: 'Points',
  },
  {
    x: 5484.375,
    y: 0.7246153950691223,
    __typename: 'Points',
  },
  {
    x: 5492.1875,
    y: 0.7343589663505554,
    __typename: 'Points',
  },
  {
    x: 5500,
    y: 0.7428205013275146,
    __typename: 'Points',
  },
  {
    x: 5507.8125,
    y: 0.7494871616363525,
    __typename: 'Points',
  },
  {
    x: 5515.625,
    y: 0.7541025876998901,
    __typename: 'Points',
  },
  {
    x: 5523.4375,
    y: 0.7576923370361328,
    __typename: 'Points',
  },
  {
    x: 5531.25,
    y: 0.7584615349769592,
    __typename: 'Points',
  },
  {
    x: 5539.0625,
    y: 0.7574359178543091,
    __typename: 'Points',
  },
  {
    x: 5546.875,
    y: 0.755384624004364,
    __typename: 'Points',
  },
  {
    x: 5554.6875,
    y: 0.7528204917907715,
    __typename: 'Points',
  },
  {
    x: 5562.5,
    y: 0.7487179636955261,
    __typename: 'Points',
  },
  {
    x: 5570.3125,
    y: 0.7433333396911621,
    __typename: 'Points',
  },
  {
    x: 5578.125,
    y: 0.7366666793823242,
    __typename: 'Points',
  },
  {
    x: 5585.9375,
    y: 0.7284615635871887,
    __typename: 'Points',
  },
  {
    x: 5593.75,
    y: 0.7200000286102295,
    __typename: 'Points',
  },
  {
    x: 5601.5625,
    y: 0.7102563977241516,
    __typename: 'Points',
  },
  {
    x: 5609.375,
    y: 0.6994871497154236,
    __typename: 'Points',
  },
  {
    x: 5617.1875,
    y: 0.6879487037658691,
    __typename: 'Points',
  },
  {
    x: 5625,
    y: 0.6758974194526672,
    __typename: 'Points',
  },
  {
    x: 5632.8125,
    y: 0.6643589735031128,
    __typename: 'Points',
  },
  {
    x: 5640.625,
    y: 0.653333306312561,
    __typename: 'Points',
  },
  {
    x: 5648.4375,
    y: 0.6412820219993591,
    __typename: 'Points',
  },
  {
    x: 5656.25,
    y: 0.6284615397453308,
    __typename: 'Points',
  },
  {
    x: 5664.0625,
    y: 0.6161538362503052,
    __typename: 'Points',
  },
  {
    x: 5671.875,
    y: 0.6033333539962769,
    __typename: 'Points',
  },
  {
    x: 5679.6875,
    y: 0.5910256505012512,
    __typename: 'Points',
  },
  {
    x: 5687.5,
    y: 0.5789743661880493,
    __typename: 'Points',
  },
  {
    x: 5695.3125,
    y: 0.5674359202384949,
    __typename: 'Points',
  },
  {
    x: 5703.125,
    y: 0.5566666722297668,
    __typename: 'Points',
  },
  {
    x: 5710.9375,
    y: 0.5464102625846863,
    __typename: 'Points',
  },
  {
    x: 5718.75,
    y: 0.5371794700622559,
    __typename: 'Points',
  },
  {
    x: 5726.5625,
    y: 0.5294871926307678,
    __typename: 'Points',
  },
  {
    x: 5734.375,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 5742.1875,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 5750,
    y: 0.5105128288269043,
    __typename: 'Points',
  },
  {
    x: 5757.8125,
    y: 0.505384624004364,
    __typename: 'Points',
  },
  {
    x: 5765.625,
    y: 0.5007692575454712,
    __typename: 'Points',
  },
  {
    x: 5773.4375,
    y: 0.4976923167705536,
    __typename: 'Points',
  },
  {
    x: 5781.25,
    y: 0.4953846037387848,
    __typename: 'Points',
  },
  {
    x: 5789.0625,
    y: 0.4938461482524872,
    __typename: 'Points',
  },
  {
    x: 5796.875,
    y: 0.4930769205093384,
    __typename: 'Points',
  },
  {
    x: 5804.6875,
    y: 0.49358972907066345,
    __typename: 'Points',
  },
  {
    x: 5812.5,
    y: 0.49435898661613464,
    __typename: 'Points',
  },
  {
    x: 5820.3125,
    y: 0.4964102506637573,
    __typename: 'Points',
  },
  {
    x: 5828.125,
    y: 0.49820512533187866,
    __typename: 'Points',
  },
  {
    x: 5835.9375,
    y: 0.5002564191818237,
    __typename: 'Points',
  },
  {
    x: 5843.75,
    y: 0.5028204917907715,
    __typename: 'Points',
  },
  {
    x: 5851.5625,
    y: 0.5058974623680115,
    __typename: 'Points',
  },
  {
    x: 5859.375,
    y: 0.5092307925224304,
    __typename: 'Points',
  },
  {
    x: 5867.1875,
    y: 0.5128205418586731,
    __typename: 'Points',
  },
  {
    x: 5875,
    y: 0.516153872013092,
    __typename: 'Points',
  },
  {
    x: 5882.8125,
    y: 0.5197435617446899,
    __typename: 'Points',
  },
  {
    x: 5890.625,
    y: 0.5225641131401062,
    __typename: 'Points',
  },
  {
    x: 5898.4375,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 5906.25,
    y: 0.528205156326294,
    __typename: 'Points',
  },
  {
    x: 5914.0625,
    y: 0.5299999713897705,
    __typename: 'Points',
  },
  {
    x: 5921.875,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 5929.6875,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 5937.5,
    y: 0.5310256481170654,
    __typename: 'Points',
  },
  {
    x: 5945.3125,
    y: 0.5302563905715942,
    __typename: 'Points',
  },
  {
    x: 5953.125,
    y: 0.5284615159034729,
    __typename: 'Points',
  },
  {
    x: 5960.9375,
    y: 0.5256410241127014,
    __typename: 'Points',
  },
  {
    x: 5968.75,
    y: 0.5220512747764587,
    __typename: 'Points',
  },
  {
    x: 5976.5625,
    y: 0.5169230699539185,
    __typename: 'Points',
  },
  {
    x: 5984.375,
    y: 0.5115384459495544,
    __typename: 'Points',
  },
  {
    x: 5992.1875,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
  {
    x: 6000,
    y: 0.5048717856407166,
    __typename: 'Points',
  },
];
