export const bed = {
  Patient: ({ bed: bedData }) => {
    const bedNumber = bedData.split('#')[2];
    return ({
      patient: {
        pid: '84773294',
        fullName: 'Rayanne Jales Gadelha',
        bed: bedNumber,
        pointOfCare: 'UTI Geral',
        initials: 'R-J-G',
        __typename: 'Patient',
        isAnonymous: bedNumber === '2',
      },
    });
  },
};
