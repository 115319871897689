export const co2Wave = [
  {
    x: 0,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 734.375,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 750,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 765.625,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 1734.375,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 1750,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 1765.625,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 2734.375,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 2750,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 2765.625,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 3734.375,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 3750,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 3765.625,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 4734.375,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 4750,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 4765.625,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 5734.375,
    y: -38.696590423583984,
    __typename: 'Points',
  },
  {
    x: 5750,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 5765.625,
    y: -27.48366928100586,
    __typename: 'Points',
  },
  {
    x: 6000,
    y: -27.48366928100586,
    __typename: 'Points',
  },
];
